import { TabsContainer } from '@campxdev/shared'
import { Box, Button } from '@mui/material'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { LeadStore } from 'store/LeadStore'
import ApplicationForm from './ApplicationForm'

import Login from './AuthPages/Login'
import Register from './AuthPages/Register'
import Eligibility from './Eligibility'
import Instructions from './Instructions'
import StepstoFollow from './StepstoFollow'
import {
  StyledAuthCards,
  StyledBanner,
  StyledBannerContent,
  StyledBannerMask,
  StyledContainer,
  StyledTitleBox,
} from './styles'

function LandingPage() {
  const tabs = [
    {
      key: 'register',
      label: 'Register',
      component: <Register />,
    },
    { key: 'login', label: 'Login', component: <Login /> },
  ]

  useEffect(() => {
    if (Cookies.get('campx_lead_tenant')) {
      Cookies.remove('campx_lead_key')
      Cookies.remove('campx_lead_tenant')
      LeadStore.initialState()
    }
  }, [])

  return (
    <>
      <StyledBanner>
        <StyledBannerContent>
          <StyledTitleBox>
            {/* <Box className="content-items">
              <img src={anurag} alt="logo" width={'300px'} />
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Typography variant="h1">Admissions Open for</Typography>
                <Typography variant="h1">Academic year 2024-25</Typography>
              </motion.div>
            </Box> */}
          </StyledTitleBox>
          <StyledAuthCards>
            <TabsContainer tabs={tabs} />
          </StyledAuthCards>
        </StyledBannerContent>
        <StyledBannerMask />
      </StyledBanner>
      <>
        <StyledContainer>
          <StepstoFollow />

          <Instructions />
          <ApplicationForm />
          <Eligibility />
        </StyledContainer>
      </>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <Button
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }}
        >
          Apply Now
        </Button>
      </Box>
    </>
  )
}

export default LandingPage
