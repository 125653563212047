const welcomeImage = require('./welcomeimage.png')
const campxLogoPrimary = require('./anuragcolor.png')
const campxLogoWhite = require('./campx_logo_white.png')
const WALogo = require('./walogo.png')
const AnuragCollege = require('./anurag-university-hyderabad-204464.jpg')
const avatarImage = require('./avatar.png')
const stepVerify = require('./verify.png')
const stepSubmit = require('./payment.png')
const stepRegister = require('./g15.png')
const stepLogin = require('./g4449.png')
const stepNotification = require('./g507.png')
const stepFill = require('./application3.png')
const instructionImg = require('./instructions.jpg')
const anurag = require('./anurag.png')
const banner = require('./banner_back.jpg')
const newauBanner = require('./anuragBanner.jpeg')
import admissionIcon from './admission.svg'
import applicationIcon from './application.svg'
import exam from './exams.png'
import loginIcon from './login.svg'
import logo from './logo.png'
import registerIcon from './register.svg'
import submitIcon from './submit.svg'
import tickGif from './tick.gif'
import uploadIcon from './uploadIcon.png'
import bsCourse from './bsCourse.png'
const UpiLogo = require('./UPILogo3x.png')
const creditCardIcon = require('./credit-card3x.png')
const debitCardIcon = require('./debit-card3x.png')
const mobileBankingIcon = require('./mobile-banking3x.png')
export {
  admissionIcon,
  anurag,
  AnuragCollege,
  applicationIcon,
  avatarImage,
  banner,
  campxLogoPrimary,
  campxLogoWhite,
  creditCardIcon,
  debitCardIcon,
  exam,
  instructionImg,
  loginIcon,
  logo,
  mobileBankingIcon,
  newauBanner,
  registerIcon,
  stepFill,
  stepLogin,
  stepNotification,
  stepRegister,
  stepSubmit,
  stepVerify,
  submitIcon,
  tickGif,
  UpiLogo,
  uploadIcon,
  WALogo,
  welcomeImage,
  bsCourse,
}
