import { FormLabel, styled, Typography } from '@mui/material'

import { useRef, useState } from 'react'
import axios from 'utils/axios'
export interface Media {
  type: 'image' | 'video' | 'audio' | 'file'
  url: string
  key: string
  fileName?: string
  id?: any
  subTypeId?: string
}
interface UploadButtonProps {
  type: 'file' | 'image' | 'video' | 'audio'
  onChange: (res: Media) => void
  uploadUrl: string
  onSuccess?: () => void
  body?: any
  fileId?: string
  refetchFn?: () => void
}

export default function UploadButton({
  type,
  onChange,
  uploadUrl,
  onSuccess,
  body,
  fileId,
  refetchFn,
}: UploadButtonProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const formRef: any = useRef()
  const fileInputRef: any = useRef()
  const handleChange = (e: any) => {
    let filesData = e.target.files
    let fileArray = Object.keys(filesData)
    fileArray.forEach(async (file) => {
      const originalFile = filesData[file]
      const fileType = filesData[file]?.name?.split('.').pop()
      const newFileName = `${fileId}.${fileType}`
      const newFile = new File([originalFile], newFileName, {
        type: originalFile.type,
      })
      const fileData = new FormData()
      fileData.append('file', fileId ? newFile : filesData[file])
      fileData.append('id', body.id)
      fileData.append('type', body.type)
      try {
        let fileKey = await axios.post(
          fileType == 'mp4' ? '/square/posts/upload/video' : uploadUrl,
          fileData,
        )
        if (onSuccess) onSuccess()
        refetchFn && refetchFn()
        onChange({
          url: fileKey.data.attachment.url,
          key: fileKey.data.attachment.key,
          type: type,
          fileName: fileKey.data.attachment.originalFileName ?? '',
          id: fileKey.data?.attachment?.id ?? '',
        })
        setLoading(false)
        formRef?.current.reset()
      } catch (error) {
        setLoading(false)
        formRef?.current.reset()
      }
    })
  }
  return (
    <>
      <form ref={formRef}>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          style={{ display: 'none' }}
          accept="video/*,image/*"
          onChange={handleChange}
        />
        <StyledUploadContainer
          htmlFor="file"
          onClick={() => fileInputRef.current.click()}
        >
          {/* <UploadIcon /> */}
          <Typography
            variant="subtitle1"
            // sx={{
            //   margin: '0px 10px',
            //   opacity: '0.6',
            // }}
          >
            Upload
          </Typography>
        </StyledUploadContainer>
      </form>
    </>
  )
}

const StyledUploadContainer = styled(FormLabel)({
  border: '1px solid #0000001A',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  height: '20px',
  gap: '10px',
  '&:hover': {
    backgroundColor: '#EFEFEF',
  },
})
export const StyledContainer = styled(FormLabel)({
  border: '1px solid #0000001A',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '10px',
  marginBottom: '10px',
})
