import { Permission } from '@campxdev/shared'
import AdmissionsForm from 'pages/AdmissionsForm/AdmissionsForm'
import { ApplicationForm } from 'pages/Application/ApplicationForm'
import InitiatePayment from 'pages/InitiatePayment'
import LandingPage from 'pages/LandingPage'
import Thankyou from 'pages/Thankyou'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const ApplyProgram = lazy(() => import('pages/ApplyProgram/ApplyProgram'))
const HomePage = lazy(() => import('pages/HomePage'))
const MyQueries = lazy(() => import('pages/MyQueries/MyQueries'))
const MyProfile = lazy(() => import('pages/MyProfile'))
export const baseRoutes = [
  {
    index: true,
    element: <Navigate to="/home" />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '/home',
    element: <LandingPage />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'apply-program',
    element: <ApplyProgram />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'my-profile/:id',
    element: <MyProfile />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'my-queries',
    element: <MyQueries />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'thank-you',
    element: <Thankyou />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'admission-form/:id',
    element: <AdmissionsForm />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '/application-form/:id',
    element: <ApplicationForm />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'my-profile/:id/payment-status/:orderId',
    element: <InitiatePayment />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
