import { Image } from '@campxdev/shared'
import { alpha, Box, Card, styled, Typography } from '@mui/material'
import { formStore, paymentMethods } from './service'
const SelectPaymentMethod = () => {
  const formState = formStore.useState((s) => s)
  return (
    <>
      <StyledContainer>
        <Typography align="center" variant="body1">
          Select Payment Mode and click on Pay to continue
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem',
            ['@media(max-width: 600px)']: {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          {paymentMethods?.map((item, index) => (
            <StyledPaymentCard
              key={index}
              onClick={() => {
                formStore.update((s) => {
                  s.paymentMethod = item?.value
                })
              }}
              isActive={item?.value == formState.paymentMethod}
            >
              <Image alt="" src={item?.icon} height={36} width={36} />
              <Typography variant="body1">{item?.label}</Typography>
            </StyledPaymentCard>
          ))}
        </Box>
      </StyledContainer>
    </>
  )
}
export default SelectPaymentMethod
export const StyledPaymentCard = styled(Card)<{ isActive?: boolean }>(
  ({ theme, isActive }) => ({
    width: '200px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    ...(isActive && {
      border: `1px solid ${theme.palette.primary.main}`,
      background: alpha(theme.palette.primary.main, 0.07),
    }),
    ['@media(max-width: 600px)']: {
      width: '250px',
    },
  }),
)
export const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '20px',
})
