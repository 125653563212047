import {
  FormDatePicker,
  FormSingleSelect,
  FormTextField,
} from '@campxdev/shared'
import {
  DeleteIcon,
  ViewIcon,
} from '@campxdev/shared/src/components/IconButtons/Icons'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { logo } from 'assets/images'
import { stateCity } from 'constants/state-city'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'utils/axios'
import * as Yup from 'yup'
import FormPayment from './FormPayment'
import UploadButton, { Media } from './UploadFile'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  fatherName: Yup.string().required("Father's name is required"),
  fatherMobile: Yup.string()
    .matches(/^\d{10}$/, "Father's mobile number must be 10 digits")
    .required("Father's mobile number is required"),
  motherName: Yup.string().required("Mother's name is required"),
  dob: Yup.date().required('Date of birth is required'),
  mobile: Yup.string()
    .matches(/^\d{10}$/, 'Contact number must be 10 digits')
    .required('Contact number is required'),
  address: Yup.string().required('Home address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State/Region is required'),
  country: Yup.string().required('Country/Region is required'),
  level: Yup.string().required('Degree level is required'),
  courseId: Yup.string().required('Pathway is required'),
  // projectedTerm: Yup.string().required('Projected term is required'),
  // university: Yup.string().required('university preference  is required'),

  programId: Yup.string().required('Program preference is required'),
  // interCgpa: Yup.number().required('cgpa  is required'),
  // interPercentage: Yup.number().required('interPercentage is required'),
  // // english: Yup.string().required('English proficiency is required'),
  // // englishScore: Yup.number()
  // //   .typeError('Must be a valid number')
  // //   .min(0, 'Minimum score is 0')
  // //   .max(120, 'Maximum score is 120')
  // //   .required('English proficiency score is required'),
  // // greScore: Yup.number()
  // //   .typeError('Must be a valid number')
  // //   .min(0, 'Minimum GRE score is 0')
  // //   .max(340, 'Maximum GRE score is 340')
  // //   .required('GRE score is required'),

  // // workExperience: Yup.string().required('Work experience is required'),
  // workDetails: Yup.string().when('workExperience', {
  //   is: 'yes',
  //   then: Yup.string().required('Work experience details are required'),
  //   otherwise: Yup.string(),
  // }),
})

const FormDetails = ({ data, courses, programs }) => {
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const params = useParams()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: data?.email ?? '',
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      fatherName: data?.guardianDetails?.fatherName ?? '',
      fatherMobile: data?.guardianDetails?.fatherMobile ?? '',
      motherName: data?.guardianDetails?.motherName ?? '',
      dob: data?.personalDetails.dob ?? '',
      mobile: data?.mobile ?? '',
      address: data?.personalDetails?.address ?? '',
      city: data?.personalDetails?.city ?? '',
      state: data?.personalDetails?.state ?? '',
      country: data?.country ?? '',
      interCgpa: data?.interDetails.interCgpa ?? null,
      interPercentage: data?.interDetails.interPercentage ?? null,
      level: 'Undergraduate',
      courseId: courses.find((item) => item.courseName === 'BS')?.id ?? '',
      programId: data?.programId ?? '',
      englishProficiency: data?.englishProficiency ?? '',
      englishProficiencyScore: data?.englishProficiencyScore ?? '',
      greScore: data?.greScore ?? '',
    },
    resolver: yupResolver(validationSchema),
  })
  console.log(data)
  const [documents, setDocuments] = useState({
    interDocuments: [],
    tenthDocuments: [],
    profileDocuments: [],
    passport: [],
  })

  const updateLead = async ({ id, data }) => {
    const response = await axios.put(`/paymentx/lead-ats/${id}`, data)
  }

  const { mutate, isLoading } = useMutation(updateLead, {
    onSuccess: (res) => {
      setOpenPaymentDialog(true)
    },
    onError: (err) => {
      console.log(err, 'onerr')
    },
  })

  const onSubmit = (formData: any) => {
    let apiData = {
      ...data,
      email: formData.email,
      country: formData.country,
      firstName: formData.firstName,
      lastName: formData.lastName,
      courseId: formData.courseId,
      programId: formData.programId,
      level: formData.level,
      choice1: formData.choice1,
      englishProficiency: formData?.englishProficiency ?? '',
      englishProficiencyScore: formData?.englishProficiencyScore ?? '',
      greScore: formData?.greScore ?? '',
      guardianDetails: {
        ...data.guardianDetails,
        fatherName: formData.fatherName,
        fatherMobile: formData.fatherMobile,
        motherName: formData.motherName,
      },
      personalDetails: {
        ...data.personalDetails,
        dob: moment(formData.dob).format('YYYY-MM-DD'),
        address: formData.address,
        city: formData.city,
        state: formData.state,
        country: formData.country,
      },
      interDetails: {
        ...data.interDetails,
        interCgpa: formData.interCgpa,
        interPercentage: formData.interPercentage,
      },
    }

    mutate({
      id: data.id,
      data: apiData,
    })

    console.log(apiData)
  }

  const onError = () => {
    console.log('This is error')
  }

  async function handleDelete(id, key) {
    const data = {
      id,
      key,
    }
    try {
      await axios.post(`/paymentx/lead-ats/${id}/remove-attachments`, data)
      queryClient.invalidateQueries('lead-details')
      toast.success('Attachment deleted successfully')
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting attachement')
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // width: '90vw',
          margin: '30px 20px',
          flexDirection: 'column',
          gap: '5vh',
        }}
      >
        <Box sx={{ maxWidth: '70vh' }}>
          <img src={logo} width={'100%'} height={'auto'}></img>
        </Box>
        <Box
          sx={{
            maxWidth: '90vh',
            border: '1px solid #CECECE',
            borderRadius: '5px',
          }}
        >
          <Box
            sx={{
              height: '45px',
              backgroundColor: '#F2F2F2',

              borderRadius: '8px 8px 0 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6"> BS Application Form</Typography>{' '}
          </Box>
          <Box
            sx={{
              backgroundColor: 'white',
              marginTop: '20px',
              padding: '10px',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Stack padding={'10'} gap={'4'}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField
                      name="email"
                      control={control}
                      fullWidth
                      disabled
                      label="Email"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      name="firstName"
                      fullWidth
                      label="First name "
                      placeholder="First Name as on Passport"
                      required
                      error={!!errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      name="lastName"
                      fullWidth
                      label="Last Name/Surname "
                      placeholder="Surname as on Passport (if u don't have a surname, just write NA)"
                      required
                      error={!!errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      name="fatherName"
                      fullWidth
                      label="Father's Name "
                      placeholder="Enter your father's name"
                      required
                      error={!!errors.fatherName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      control={control}
                      name="fatherMobile"
                      fullWidth
                      label="Father's Mobile Number "
                      placeholder="Enter Mobile Number"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      control={control}
                      name="motherName"
                      fullWidth
                      label="Mother's Name "
                      placeholder="Enter your mother's name"
                      required
                      error={!!errors.motherName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormDatePicker
                      control={control}
                      placeholder="DOB as on passport (DD/MM/YYYY)"
                      name="dob"
                      required
                      label="Date of Birth"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      control={control}
                      name="mobile"
                      fullWidth
                      label="Contact Number "
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      name="address"
                      control={control}
                      fullWidth
                      label="Home Address "
                      placeholder="Address as on Passport"
                      required
                      multiline
                      rows={2}
                      error={!!errors.address}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormSingleSelect
                      control={control}
                      name="country"
                      size="small"
                      label="Country/Region "
                      required
                      options={[
                        { label: 'India', value: 'India' },
                        { label: 'Other', value: 'Other' },
                      ]}
                      error={!!errors.country}
                    />
                  </Grid>
                  {watch('country') == 'Other' ? (
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        sx={{
                          div: {
                            minHeight: '40px',
                          },
                          input: {
                            minHeight: '40px',
                          },
                          fieldset: {
                            minHeight: '40px !important',
                          },
                        }}
                        control={control}
                        name="state"
                        label="State/Region "
                        required
                        error={!!errors.state}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={4}>
                      <FormSingleSelect
                        control={control}
                        name="state"
                        size="small"
                        label="State/Region"
                        required
                        options={Object.keys(stateCity)?.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        error={!!errors.state}
                      />
                    </Grid>
                  )}
                  {watch('country') == 'Other' ? (
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        sx={{
                          div: {
                            minHeight: '40px',
                          },
                          input: {
                            minHeight: '40px',
                          },
                          fieldset: {
                            minHeight: '40px !important',
                          },
                        }}
                        control={control}
                        name="city"
                        label="City "
                        required
                        error={!!errors.city}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={4}>
                      <FormSingleSelect
                        control={control}
                        name="city"
                        label="City"
                        size="small"
                        required
                        options={stateCity[watch('state')]?.map(
                          (item: any) => ({
                            label: item,
                            value: item,
                          }),
                        )}
                        error={!!errors.city}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <FormSingleSelect
                      control={control}
                      name="level"
                      size="small"
                      label="ASU Degree Level "
                      required
                      disabled={true}
                      options={[
                        { label: 'Undergraduate', value: 'Undergraduate' },
                        { label: 'Graduate', value: 'Graduate' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormSingleSelect
                      control={control}
                      name="courseId"
                      size="small"
                      label="ASU Pathway "
                      required
                      options={courses.map((item) => {
                        return {
                          label: item?.courseName,
                          value: item?.id,
                        }
                      })}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormSingleSelect
                      control={control}
                      name="programId"
                      size="small"
                      label="Program Preference "
                      required
                      options={programs
                        .filter((item) => item.courseId == watch('courseId'))
                        .map((item) => {
                          return {
                            label: item?.branchName,
                            value: item?.id,
                          }
                        })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormSingleSelect
                      control={control}
                      name="englishProficiency"
                      label="English Proficiency"
                      options={[
                        { label: 'Beginner ', value: 'beginner' },
                        { label: 'Intermediate', value: 'intermediate' },
                        { label: 'Expert', value: 'expert' },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      control={control}
                      name="englishProficiencyScore"
                      label="English Proficiency Score"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      name="greScore"
                      label="GRE Score"
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormTextField
                      control={control}
                      name="interPercentage"
                      label="12th Marks %"
                      placeholder="Enter the value"
                      required
                    />
                  </Grid>

                  <Grid
                    item
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Typography color="#12121280">OR</Typography>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <FormTextField
                      control={control}
                      name="interCgpa"
                      label="12th Marks GPA"
                      placeholder="Enter the value"
                      required
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      name="graduationDegree"
                      label="Graduation Degree and GPA/Percentage"
                      placeholder="B-TECH CSE/2022/8.5"
                      required
                      fullWidth
                    />
                  </Grid> */}
                  {/* <Grid item xs={12}>
                    <FormRadioGroup
                      control={control}
                      name="work"
                      label="Work Experience"
                      options={[
                        { value: 'yes', label: 'Yes Full Time' },
                        { value: 'no', label: 'No' },
                      ]}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12} marginBottom={2}>
                    <FormTextField
                      control={control}
                      name="workExperience"
                      label="Details of your work experience along with duration ,if applicable"
                      fullWidth
                      rows={2}
                    />
                  </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} marginTop={2}>
                    <Box>
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          margin: '0px 0px  6px',
                          opacity: '0.6',
                        }}
                      >
                        10th Marksheet
                      </Typography>
                      <UploadButton
                        type="image"
                        uploadUrl={`/paymentx/lead-ats/upload-attachment`}
                        body={{
                          id: params.id,
                          type: 'tenth',
                        }}
                        fileId={data?.uniqueId}
                        onSuccess={() => {
                          toast.success(`DocumentUploaded Successfully`)

                          queryClient.invalidateQueries('lead-details')
                        }}
                        onChange={function (res: Media): void {
                          throw new Error('Function not implemented.')
                        }}
                      />
                    </Box>

                    <Box sx={{ marginTop: '10px' }}>
                      {data.attachments &&
                        data.attachments
                          .filter((item) => item.subtype === 'tenth')
                          ?.map((item, index) => (
                            <StyledContainer key={index}>
                              <Box>
                                Uploaded File -{' '}
                                {moment(item?.createdAt).format('YYYY-MM-DD')}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  justifyContent: 'center',
                                  gap: '2px',
                                }}
                              >
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ViewIcon />
                                </a>
                                <IconButton
                                  onClick={() =>
                                    handleDelete(
                                      item.typeId || data.id,
                                      item.key,
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledContainer>
                          ))}
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} marginTop={2}>
                    <Box>
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          margin: '0px 0px  6px',
                          opacity: '0.6',
                        }}
                      >
                        12th Marksheet
                      </Typography>
                      <UploadButton
                        type="image"
                        uploadUrl={`/paymentx/lead-ats/upload-attachment`}
                        body={{
                          id: params.id,
                          type: 'intermediate',
                        }}
                        fileId={data?.uniqueId}
                        onSuccess={() => {
                          toast.success(`DocumentUploaded Successfully`)

                          queryClient.invalidateQueries('lead-details')
                        }}
                        onChange={function (res: Media): void {
                          throw new Error('Function not implemented.')
                        }}
                      />
                    </Box>
                    <Box sx={{ marginTop: '10px' }}>
                      {data.attachments &&
                        data.attachments
                          .filter((item) => item.subtype === 'intermediate')
                          ?.map((item, index) => (
                            <StyledContainer key={index}>
                              <Box>
                                Uploaded File -{' '}
                                {moment(item?.createdAt).format('YYYY-MM-DD')}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  justifyContent: 'center',
                                  gap: '2px',
                                }}
                              >
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ViewIcon />
                                </a>
                                <IconButton
                                  onClick={() =>
                                    handleDelete(
                                      item.typeId || data.id,
                                      item.key,
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledContainer>
                          ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          margin: '0px 0px  6px',
                          opacity: '0.6',
                        }}
                      >
                        Passport Copy
                      </Typography>
                      <UploadButton
                        type="image"
                        uploadUrl={`/paymentx/lead-ats/upload-attachment`}
                        body={{
                          id: params.id,
                          type: 'passport',
                        }}
                        fileId={data?.uniqueId}
                        onSuccess={() => {
                          toast.success(`DocumentUploaded Successfully`)

                          queryClient.invalidateQueries('lead-details')
                        }}
                        onChange={function (res: Media): void {
                          throw new Error('Function not implemented.')
                        }}
                      />
                    </Box>
                    <Box sx={{ marginTop: '10px' }}>
                      {data.attachments &&
                        data.attachments
                          .filter((item) => item.subtype === 'passport')
                          ?.map((item, index) => (
                            <StyledContainer key={index}>
                              <Box>
                                Uploaded File -{' '}
                                {moment(item?.createdAt).format('YYYY-MM-DD')}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  justifyContent: 'center',
                                  gap: '2px',
                                }}
                              >
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ViewIcon />
                                </a>
                                <IconButton
                                  onClick={() =>
                                    handleDelete(
                                      item.typeId || data.id,
                                      item.key,
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledContainer>
                          ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography
                        // variant="subtitle2"
                        sx={{
                          margin: '0px 0px  6px',
                          opacity: '0.6',
                        }}
                      >
                        Photograph
                      </Typography>
                      <UploadButton
                        type="image"
                        uploadUrl={`/paymentx/lead-ats/upload-attachment`}
                        body={{
                          id: params.id,
                          type: 'profile',
                        }}
                        fileId={data?.uniqueId}
                        onSuccess={() => {
                          toast.success(`DocumentUploaded Successfully`)

                          queryClient.invalidateQueries('lead-details')
                        }}
                        onChange={function (res: Media): void {
                          throw new Error('Function not implemented.')
                        }}
                      />
                    </Box>
                    <Box sx={{ marginTop: '10px' }}>
                      {data.attachments &&
                        data.attachments
                          .filter((item) => item.subtype === 'profile')
                          ?.map((item, index) => (
                            <StyledContainer key={index}>
                              <Box>
                                Uploaded File -{' '}
                                {moment(item?.createdAt).format('YYYY-MM-DD')}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'baseline',
                                  justifyContent: 'center',
                                  gap: '2px',
                                }}
                              >
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <ViewIcon />
                                </a>
                                <IconButton
                                  onClick={() =>
                                    handleDelete(
                                      item.typeId || data.id,
                                      item.key,
                                    )
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </StyledContainer>
                          ))}
                    </Box>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    my: '20px',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#12121280',
                  }}
                >
                  By submitting my information, I consent to {''}
                  <a href="https://45537609.fs1.hubspotusercontent-na1.net/hubfs/45537609/Cintana%20Privacy%20Policy.pdf">
                    the contact and privacy statement {''}
                  </a>
                  of ASU and Cintana.
                </Box>

                <Box
                  sx={{
                    marginBottom: '20px',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#12121280',
                  }}
                >
                  I hereby also certify that the documents/ information as
                  provided above is true. In future if any documents /
                  information provided is found to be incorrect/forged, my
                  application is likely to be disqualified.
                </Box>
                <Box>
                  <Button variant="outlined" type="submit">
                    Submit & Pay
                  </Button>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        sx={{
          padding: '1rem',
          width: '100%',

          maxHeight: 'calc(100vh - 64px)',
          overflow: 'auto',
        }}
      >
        <StyledDialogHeader>
          <DialogTitle>{'Make Payment'}</DialogTitle>
        </StyledDialogHeader>
        <FormPayment close={() => setOpenPaymentDialog(false)} />
      </Dialog>
    </>
  )
}

export default FormDetails

const StyledDialogHeader = styled(Box)(() => ({
  height: '64px',
  backgroundColor: '#f7f7f7',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  padding: '0.6rem 1rem',
  paddingBottom: '10px',
}))
export const StyledContainer = styled(FormLabel)({
  border: '1px solid #0000001A',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '10px',
  marginBottom: '10px',
})
