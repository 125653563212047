import { ActionButton, axiosErrorToast } from '@campxdev/shared'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// import SelectPaymentMethod from './PaymentOption'
import { toast } from 'react-toastify'
import SelectPaymentMethod from './PaymentOption'
import { createBillDeskOrder, formStore } from './service'

function FormPayment({ close }) {
  const storeData = formStore.useState()

  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [orderId, setOrderId] = useState(null)

  const navigate = useNavigate()

  const handelPayment = async () => {
    if (!storeData?.paymentMethod) {
      toast.warn('Please Select Payment Method')
      return
    }
    setLoading(true)
    try {
      const domain = location.protocol + '//' + location.host
      const url = `${domain}/my-profile/${id}/payment-status`
      let res = await createBillDeskOrder({
        amount: 2000,
        leadId: id,
        redirectUrl: url,
        isWeb: true,
        examMode: storeData.examMode,
        paymentMethod: storeData?.paymentMethod,
        examSlot: storeData.examSlot,
        examDate: storeData.examDate,
        examCenter: storeData.examCenter,
      })

      setOrderId(res?.order?.id)
      navigate(
        `/my-profile/${id}/payment-status/${res?.paymentDetails?.orderId}`,
      )
      close()
    } catch (err) {
      setLoading(false)
      axiosErrorToast(err)
    }
  }

  const handleClose = () => {
    formStore.update((s) => {
      s.formValidated = false
    })
    close()
  }
  return (
    <>
      <Stack gap={4} m={2}>
        <SelectPaymentMethod />
        <Stack gap={2} direction={'row'}>
          <ActionButton onClick={handleClose} variant="outlined" fullWidth>
            Cancel
          </ActionButton>
          <ActionButton loading={loading} fullWidth onClick={handelPayment}>
            Pay
          </ActionButton>
        </Stack>
      </Stack>
    </>
  )
}

export default FormPayment
