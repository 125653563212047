import { Spinner } from '@campxdev/shared'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import axios from 'utils/axios'
import FormDetails from './FormDetails'

export const ApplicationForm = () => {
  const { id } = useParams()
  const { data, isLoading } = useQuery('lead-details', () => {
    return axios.get(`/paymentx/leads/${id}`).then((res) => res.data)
  })

  const { data: courses, isLoading: courseLoading } = useQuery(
    'courses',
    () => {
      return axios.get('/paymentx/domain/courses').then((res) => res.data)
    },
  )
  const { data: programs, isLoading: programLoading } = useQuery(
    'programs',
    () => {
      return axios.get('/paymentx/domain/programs').then((res) => res.data)
    },
  )
  if (isLoading || courseLoading || programLoading) {
    return <Spinner />
  }

  return (
    <div>
      <FormDetails data={data} courses={courses} programs={programs} />
    </div>
  )
}
